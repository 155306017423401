<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
var moment = require('moment');
moment.locale('fr');
import { Api } from '../../../helpers';
import articleCard from "../../../components/widgets/card/articleCard.vue";
import loaderProcessVue from "../../../components/widgets/loaderProcess.vue";

/**
 * Projects-overview component
 */
export default {
  page: {
    title: "Projects Overview",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader, articleCard, loaderProcessVue, },
  data() {
    return {
      titre: "",
      progress: 0,
      process: false,
      moment: moment,
      categorie: [],
      articles: [],
      title: "Details Catégorie",
      items: [
        {
          text: "Catégorie",
          href: "/blog/categArticle"
        },
        {
          text: "Détails",
          active: true
        }
      ]
    };
  },
  async mounted() {
    this.titre = "Récupération des données"
    this.process = true
    const id = this.$route.params.id
    const result = await Api.get(`/actualite/rest/categories/all`)
    const allCateg = result.data.content
    this.categorie = allCateg.find(categ => categ.id == id)
    if (this.categorie) {
      this.image = this.categorie.images.url
    }

    const response = await Api.get(`/actualite/rest/article/${this.$route.params.id}/article`)
    this.articles = response.data.content
    this.process = false
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <loaderProcessVue :title="titre" :progress="progress" :visible="process"></loaderProcessVue>

    <div class="card" style="padding: 30px; border-radius: 7px;">
      <div class="row">
        <div class="col-xl-4">
          <div class="product-detai-imgs" style="">
            <img :src="image" alt style="
            object-fit: cover;
            height: 200px;
            width: 100%;
            border-radius: 10px;
            margin-bottom: 20px;
            " />
          </div>
        </div>
        <div class="col-xl-1"></div>
        <div class="col-xl-7">
          <div class="mt-3">
            <div style="text-align: justify;">
              <h2>{{ categorie.name }}</h2>
              <br>
              <h5><strong>{{ $t("pages.overviewCateg.dataCateg.description") }}</strong> : {{ categorie.description }}
              </h5>
              <h5><strong>Nombre d'article</strong> : {{ articles.length }}</h5>
            </div>
          </div>
        </div>
      </div>
    </div>

    <br><br>
    <div class="row">
      <div class="col-xl-12 col-lg-8">
        <div class="card">
          <div>
            <div class="row p-5">
              <div class="col-xl-12">
                  <articleCard v-for="article in articles" :key="article.id" :article="article"></articleCard>
              </div>
            </div>
          </div>
          <!-- Nav tabs -->
        </div>
      </div>
    </div>
  </Layout>
</template>